import { lazyRetry as lazy } from "@utils/lazyRetry";
import { SCOPES } from "@contexts/permission";

const routes = [
    {
        exact: true,
        scope: SCOPES.ADVERTS.VIEW,
        guard: "auth",
        path: "/dashboard/adverts",
        layout: "dashboard",
        title: "routes.adverts",
        component: lazy(() => import("./index")),
    },
    {
        exact: true,
        scope: SCOPES.ADVERTS.VIEW,
        guard: "auth",
        path: "/dashboard/adverts/maxAdverts",
        layout: "dashboard",
        title: "routes.adverts",
        component: lazy(() => import("./index_max_adverts")),
    },
];

export default routes;
