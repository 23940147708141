import { Book as BookIcon, Upload as UploadIcon, Bell, Check, X } from "react-feather";
import { PriorityHigh } from "@mui/icons-material";
import _ from "lodash";

export const flowsMapping = (notification, history) => ({
    products_import: {
        default: () => {
            const notificationId = _.get(notification, "id");
            history(`/dashboard/notifications/${notificationId}`);
        },
    },
    icons: {},
    link: {
        default: () => {
            const link = _.get(notification, "link", "#");
            window.open(link, "_blank");
        },
    },
    default: {
        default: () => {
            console.log("Default Function");
        },
    },
    products_items_import: {
        default: () => {
            history("/dashboard/catalog/products");
        },
    },
});

export const iconsMapping = {
    default: Bell,
    products: UploadIcon,
    icons: UploadIcon,
    other: BookIcon,
};

export const severityMapping = {
    warning: {
        color: "warning",
        symbol: <PriorityHigh style={{ minWidth: 15, width: 13 }} />,
    },
    success: {
        color: "success",
        symbol: <Check style={{ minWidth: 15, width: 13 }} />,
    },
    info: {
        color: "info",
        symbol: "i",
    },
    error: {
        color: "error",
        symbol: <X style={{ minWidth: 15, width: 15 }} />,
    },
    default: {
        color: "info",
        symbol: "i",
    },
};
