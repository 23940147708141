import { Alert, AlertTitle, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDispatch, useSelector } from "@store";
import _ from "lodash";
import importSlice, { getIsImportInProgress } from "@slices/import";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./import_alert.styles";

const useStyles = makeStyles(styles);
const ImportAlert = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const importsStatuses = t("imports.statuses", { returnObjects: true });
    const getImportStatus = async () => {
        await storeDispatch(getIsImportInProgress());
    };
    const isAlertVisible = useSelector((state) => state.import.isAlertVisible);
    const dispatch = useDispatch();
    useEffect(() => {
        getImportStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const storeDispatch = useDispatch();
    const importStatus = useSelector((state) => state.import.status);
    const message = _.get(importsStatuses, `${importStatus}.message`);
    const severity = _.get(importsStatuses, `${importStatus}.color`);
    const description = _.get(importsStatuses, `${importStatus}.description`);
    return (
        <div>
            {isAlertVisible && importStatus !== "" && (
                <Alert
                    variant="filled"
                    severity={severity}
                    className={classes.root}
                    onClose={() => {
                        dispatch(importSlice.actions.toggleAlertVisibility());
                    }}
                >
                    <AlertTitle className={classes.title}>{message}</AlertTitle>
                    <Typography className={classes.description}>{description}</Typography>
                </Alert>
            )}
        </div>
    );
};

export default ImportAlert;
