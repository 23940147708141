import { Navigate } from "react-router-dom";
import useHasPermissions from "@hooks/use_has_permissions";
import PropTypes from "prop-types";

/**
 * Component used to avoid rendering the component if user does not have appropriate permission for the scope
 *
 * @param {string} to - scope to which user should have permission, check @SCOPE from @contexts/permission/index
 * @param {React.ReactNode} children
 * @param {string} redirectPath - redirect path when is used to protect Routes
 */
const Restricted = ({ to, children, redirectPath }) => {
    const allowed = useHasPermissions(to);

    // If the user has that permission, render the children
    if (allowed) {
        return children;
    }

    // If the user does not have permission, redirect to dashboard
    if (redirectPath) return <Navigate to={redirectPath} replace />;

    return null;
};

Restricted.propTypes = {
    to: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    children: PropTypes.node.isRequired,
    redirectPath: PropTypes.string,
};

export default Restricted;
