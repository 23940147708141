const styles = (theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1),
    },
    container: {
        // backgroundColor: "#FFFFFF",
    },
    badge: {
        "& .MuiBadge-badge": {
            marginRight: -8,
            marginBottom: -8,
            fontSize: 12,
            height: 18,
            width: 18,
            minWidth: 18,
            padding: 0,
            fontWeight: "bold",
        },
    },
    popover: {
        width: 440,
    },
    icon: {
        background: "rgba(57, 73, 171, 0.08)",
        color: "#3949AB",
        overflow: "visible",
    },
    deleteNotification: {
        height: 20,
        width: 20,
        // position: 'absolute',
        // right: 0
    },
    deleteNotificationWrapper: {
        paddingRight: 15,
    },
    moreButton: {
        padding: 0,
        alignItems: "flex-end",
    },
    boldText: {
        fontWeight: "bold",
    },
    loading: {
        marginLeft: theme.spacing(2),
    },
    listItemContainer: {
        cursor: "pointer",
    },
    listItemRead: {
        // backgroundColor: "#FFFFFF",
    },
    listItemNotRead: {
        backgroundColor: "#EEEEEE",
    },
});

export default styles;
