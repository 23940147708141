import { createSlice } from "@reduxjs/toolkit";
import { callApi, Endpoints } from "@utils/api";
import _ from "lodash";
import notificationSlice from "@slices/notification";

const initialState = {
    status: "",
    isAlertVisible: false,
    finished: false,
};

const importSlice = createSlice({
    name: "import",
    initialState,
    reducers: {
        startImportProgress(state) {
            return { ...state, status: "pending", isAlertVisible: true, finished: false };
        },
        endImportProgress(state, action) {
            const importResult = _.get(action, "payload", {});
            return { ...state, ...importResult, finished: true };
        },
        toggleAlertVisibility: (state) => {
            state.isAlertVisible = !state.isAlertVisible;
        },
    },
});

export const reducer = importSlice.reducer;

export const getIsImportInProgress =
    (importKey = null) =>
    async (dispatch) => {
        const { data: importResponse } = await callApi(Endpoints.ProductsBulkStatus);
        if (importKey || (importResponse && _.size(importResponse) > 0)) {
            dispatch(importSlice.actions.startImportProgress());
            const importFileName = _.get(importResponse[0], "fileName", "");
            importKey = importKey ? importKey : importFileName.slice(0, 36);
            const refreshNotifications = async () => {
                const { data, error } = await callApi(Endpoints.GetNotifications, {}, "get");
                let foundKeyNotification;
                if (!error && data) {
                    foundKeyNotification = data.find((notification) => {
                        return notification?.type === "products_import" && _.get(notification, "data.key", "").includes(importKey);
                    });
                }
                if (!foundKeyNotification) {
                    setTimeout(refreshNotifications, 5000);
                } else {
                    dispatch(notificationSlice.actions.saveNotifications(data));
                    const importStatus = _.get(foundKeyNotification, "severity", "");
                    dispatch(importSlice.actions.endImportProgress({ status: importStatus }));
                }
                return foundKeyNotification;
            };
            await refreshNotifications(importKey);
        }
    };

export default importSlice;
