import React from "react";
import { isEmpty } from "lodash";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "@hooks/use_auth";
import { COACH_START_PATH } from "@pages/coach/start/index.routes";
import { useSelector } from "@store";
import { COACH_ONBOARDING_PATH } from "@pages/coach/onboarding/index.routes";
import { LOGOUT_PATH } from "@pages/auth/logout/index.routes";
import { COACH_ONBOARDING_COMPLETE_PATH } from "@pages/coach/onboarding_complete/index.routes";
import { DASHBOARD_PATH } from "@pages/dashboard/overview/index.routes";

const coachSpecificPages = [COACH_START_PATH, COACH_ONBOARDING_PATH, COACH_ONBOARDING_COMPLETE_PATH];

const AuthGuard = ({ children }) => {
    const { id, ...company } = useSelector((state) => state.company);
    const { isAuthenticated } = useAuth();

    // Redirect back to dashboard if it was accessed unauthorized
    const url = useLocation().pathname;
    const pathname = useLocation().pathname;
    const isDashboard = !!~pathname.indexOf("dashboard");

    const redirectUrl = localStorage.getItem("url");

    if (!isAuthenticated) {
        url && isDashboard && localStorage.setItem("url", url);
        return <Navigate to="/login" />;
    } else {
        if (company.isCoach) {
            if (isEmpty(company.coachProfile)) {
                const shouldRedirect = ![...coachSpecificPages, LOGOUT_PATH].includes(pathname);

                if (shouldRedirect) {
                    return <Navigate to={COACH_START_PATH} />;
                }
            } else if (redirectUrl && isAuthenticated) {
                localStorage.removeItem("url");
                return <Navigate to={redirectUrl} />;
            }
        } else {
            if (coachSpecificPages.includes(pathname)) {
                return <Navigate to={DASHBOARD_PATH} />;
            } else if (redirectUrl && isAuthenticated) {
                localStorage.removeItem("url");
                return <Navigate to={redirectUrl} />;
            }
        }
    }

    return <>{children}</>;
};

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default AuthGuard;
