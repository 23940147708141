import React from "react";
import ReactDOM from "react-dom/client";
import "rc-time-picker/assets/index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

import "@utils/i18n";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";

import store from "@store";
import { SettingsProvider } from "@contexts/settings_context";
import { Provider } from "react-redux";

// See https://serverless-stack.com/chapters/configure-aws-amplify.html
import { Amplify } from "aws-amplify";
import TAwsConfig from "./awsConfig";

Amplify.configure({
    Storage: {
        region: TAwsConfig.s3.REGION,
        bucket: TAwsConfig.s3.BUCKET,
    },
    API: {
        endpoints: [
            {
                endpoint: TAwsConfig.apiGateway.URL,
                region: TAwsConfig.apiGateway.REGION,
            },
        ],
    },
});

// https://serverless-stack.com/chapters/handle-routes-with-react-router.html

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <SettingsProvider>
            <App />
        </SettingsProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
