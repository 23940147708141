import PermissionContext from "@contexts/permission";
import { useContext } from "react";

const useHasPermissions = (scopes) => {
    const { isAllowed } = useContext(PermissionContext);

    return !scopes || isAllowed(scopes);
};

export default useHasPermissions;
