/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath, Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, Link, List, ListSubheader, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
    HelpCircle as HelpIcon,
    ShoppingCart as ShoppingCartIcon,
    Folder as FolderIcon,
    PieChart as PieChartIcon,
    Book as BookIcon,
    Settings as SettingsIcon,
    TrendingUp,
} from "react-feather";
import Logo from "@components/logo";
import NavItem from "./nav_item";
import styles from "./index.styles";
import { useTranslation } from "react-i18next";

import Restricted from "@components/restricted";
import { SCOPES } from "@contexts/permission";

const Sections = () => {
    const { t } = useTranslation();
    const sections = [
        {
            subheader: "",
            items: [
                {
                    title: t("navigation.dashboard"),
                    icon: PieChartIcon,
                    href: "/dashboard",
                    style: { marginBottom: 5 },
                },
            ],
        },
        {
            subheader: t("navigation.catalog"),
            items: [
                {
                    title: t("navigation.products"),
                    icon: FolderIcon,
                    href: "/dashboard/catalog/products",
                    includeSubPaths: true,
                },

                {
                    scope: SCOPES.DISCOUNTS.VIEW,
                    title: t("navigation.discounts_&_visibilities"),
                    icon: BookIcon,
                    href: "/dashboard/catalog/product-groups",
                    includeSubPaths: true,
                },
                {
                    title: t("navigation.bookings"),
                    icon: ShoppingCartIcon,
                    href: "/dashboard/catalog/bookings",
                    includeSubPaths: true,
                },
                {
                    title: t("navigation.enquiries"),
                    icon: HelpIcon,
                    href: "/dashboard/catalog/enquiries",
                    includeSubPaths: true,
                },
            ],
        },
        {
            scope: SCOPES.ADVERTS.VIEW,
            subheader: t("navigation.advertising"),
            items: [
                {
                    title: t("navigation.adverts"),
                    href: "/dashboard/adverts",
                    icon: TrendingUp,
                    includeSubPaths: true,
                },
            ],
        },
        {
            subheader: t("navigation.company"),
            items: [
                {
                    title: t("navigation.settings"),
                    href: "/dashboard/account/company",
                    icon: SettingsIcon,
                    includeSubPaths: true,
                },
            ],
        },
    ];
    return sections;
};

function renderNavItems({ items, pathname, depth = 0 }) {
    return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;
    const match = matchPath(item.href + (item.includeSubPaths ? "/*" : ""), pathname);

    if (item.items) {
        const open = matchPath(item.href, pathname);

        acc.push(
            <Restricted to={item.scope} key={`restrict_${key}`}>
                <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
                    {renderNavItems({
                        depth: depth + 1,
                        pathname,
                        items: item.items,
                    })}
                </NavItem>
            </Restricted>
        );
    } else {
        acc.push(
            <Restricted to={item.scope} key={`restrict_${key}`}>
                <NavItem
                    depth={depth}
                    href={item.href}
                    icon={item.icon}
                    info={item.info}
                    key={key}
                    title={item.title}
                    active={match}
                    style={item.style}
                />
            </Restricted>
        );
    }

    return acc;
}

const useStyles = makeStyles(styles);

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));
    const { t } = useTranslation();
    // const variant = useBreakpointValue({ xs: 'temporary', md: 'permanent' });

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Box sx={{ display: { lg: "none", md: "block" } }} p={2} display="flex" justifyContent="center">
                    <RouterLink to="/">
                        <Logo color={lgDown ? "gray" : "white"} />
                    </RouterLink>
                </Box>
                <Box>
                    {Sections().map((section) => (
                        <Restricted to={section.scope} key={`restrict_${section.subheader}`}>
                            <List
                                key={section.subheader}
                                subheader={
                                    <ListSubheader disableGutters disableSticky className={classes.subheader}>
                                        {section.subheader}
                                    </ListSubheader>
                                }
                            >
                                {renderNavItems({
                                    items: section.items,
                                    pathname: location.pathname,
                                })}
                            </List>
                        </Restricted>
                    ))}
                </Box>
                <Divider />
                <Box className={classes.contactWrapper}>
                    <Box bgcolor="background.default" className={classes.contactSubWrapper}>
                        <Typography variant="h5" color="textPrimary" className={classes.contactHeader}>
                            {t("navigation.need_help")}
                        </Typography>
                        <Link href="https://partner.helpdesk.semigator.de/" target="_blank" className={classes.contactLink}>
                            {t("navigation.helpdesk")}
                        </Link>
                    </Box>
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <Drawer
            sx={{ zIndex: (theme) => theme.zIndex.drawer + (lgDown ? 2 : 0) }}
            anchor="left"
            classes={{
                paper: lgDown ? classes.mobileDrawer : classes.desktopDrawer,
            }}
            onClose={lgDown ? onMobileClose : undefined}
            open={lgDown ? openMobile : true}
            variant={lgDown ? "temporary" : "permanent"}
        >
            {content}
        </Drawer>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;
