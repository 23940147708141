import PermissionContext from "@contexts/permission";
import usePermissions from "@hooks/use_permissions";

const hasPermission = (permissions, scopes) => {
    if (Array.isArray(scopes)) return scopes.some((scope) => permissions[scope]);

    return permissions[scopes];
};

const PermissionProvider = ({ children }) => {
    const permissions = usePermissions();

    const isAllowed = (scopes) => hasPermission(permissions, scopes);

    return <PermissionContext.Provider value={{ isAllowed }}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
