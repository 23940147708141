import { lazyRetry as lazy } from "@utils/lazyRetry";
import { SCOPES } from "@contexts/permission";

const routes = [
    {
        exact: false,
        scope: SCOPES.ADVERTS.EDIT,
        guard: "auth",
        path: "/dashboard/adverts/:advertId/*",
        layout: "dashboard",
        title: "routes.editAdvert",
        component: lazy(() => import("./index")),
    },
];

export default routes;
