import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { AppBar, Box, Grid, IconButton, Toolbar, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Menu as MenuIcon } from "react-feather";
import Logo from "@components/logo";
import Account from "./account";
import Notifications from "./notifications";
import styles from "./index.styles";

const useStyles = makeStyles(styles);

const TopBar = ({ className, onMobileNavOpen, showBurgerMenu = true, ...rest }) => {
    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, className)} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} {...rest}>
            <Toolbar className={classes.toolbar}>
                {showBurgerMenu && (
                    <IconButton
                        sx={{ display: { lg: "none" } }}
                        data-cy="menuOpen"
                        color="inherit"
                        onClick={onMobileNavOpen}
                        className={classes.burgerIcon}
                    >
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                )}
                <Box>
                    <RouterLink to="/dashboard" className={classes.logoLink}>
                        <Logo className={classes.logo} />
                    </RouterLink>
                </Box>
                <Box ml={2} flexGrow={1} />
                <Box ml={2}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Notifications />
                        </Grid>
                        <Grid item>
                            <Account />
                        </Grid>
                    </Grid>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {},
};

export default TopBar;
