const styles = (theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        color: "white",
    },
    title: {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",
    },
    description: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "18px",
    },
});

export default styles;
