import { createSelector, createSlice } from "@reduxjs/toolkit";
import { callApi, Endpoints } from "@utils/api";
import _ from "lodash";
import { filteredObject } from "@utils/utils";

const initialState = {
    id: "",
    name: "",
    email: "",
    taxId: "",
    contactData: {
        country: "",
        city: "",
        postalCode: "",
        streetName: "",
        additionalAddress: "",
        vat: "",
        tax: "",
        phone: "",
        website: "",
    },
    billingData: {
        useContactAddress: true,
        country: "",
        city: "",
        additionalAddress: "",
        companyName: "",
        contactFirstName: "",
        contactLastName: "",
        isContactOnInvoice: false,
        streetName: "",
        postalCode: "",
        vat: "",
        contactEmail: "",
    },
    profile: {
        logo: null,
        description: "",
    },
    settings: {
        advertLimit: 0,
        allowDiscounts: false,
    },
    isCoach: false,
    coachProfile: {},
};

const slice = createSlice({
    name: "company",
    initialState,
    reducers: {
        getTenant(state, action) {
            const tenant = _.get(action, "payload.tenant", {});
            const { id = state.id, name = state.name, email = state.email, taxId = state.taxId, isCoach, coachProfile } = tenant;

            state = _.merge(state, {
                id,
                name,
                email,
                taxId,
                contactData: {
                    ..._.omitBy(_.pick(tenant.contactData, Object.keys(initialState.contactData)), _.isUndefined),
                },
                billingData: {
                    ..._.omitBy(_.pick(tenant.billingData, Object.keys(initialState.billingData)), _.isUndefined),
                },
                profile: {
                    ..._.omitBy(_.pick(tenant.profile, Object.keys(initialState.profile)), _.isUndefined),
                },
                settings: {
                    ..._.omitBy(_.pick(tenant.settings, Object.keys(initialState.settings)), _.isUndefined),
                },
                isCoach,
                coachProfile,
            });
        },
        createCoachProfile(state, action) {
            state.coachProfile = _.get(action, "payload.coachProfile", {});
        },
    },
});

export const reducer = slice.reducer;

export const getTenant = (tenantId) => async () => {
    const { data, error } = await retrieveTenant(tenantId);
    if (data) {
        return dispatchTenant(data);
    }
    return { error: error };
};

export const retrieveTenant = async (tenantId) => {
    return callApi(Endpoints.GetTenant(tenantId), {}, "get");
};

export const dispatchTenant = (data) => async (dispatch) => {
    dispatch(slice.actions.getTenant(data));
    return { data };
};

export const updateTenant = (tenantId, payload) => async (dispatch) => {
    const tenantPayload = filteredObject(payload);

    const { data, error } = await callApi(Endpoints.UpdateTenant(tenantId), tenantPayload, "put");

    if (data) {
        dispatch(slice.actions.getTenant(data));
        return { data };
    }

    return { error: error };
};

export const createCoachProfile = (tenantId, payload) => async (dispatch) => {
    const { data, error } = await callApi(Endpoints.GetCoachProfiles, payload, "post");

    if (data) {
        dispatch(slice.actions.createCoachProfile(data));
        return { data };
    }
    return { error: error };
};

export const selectTenantPermissions = createSelector(
    (state) => state.company,
    (company) => {
        const { advertLimit, allowDiscounts } = company?.settings;

        return {
            allowAdverts: !!+advertLimit,
            allowDiscounts,
        };
    }
);

export const selectCompanyCountry = createSelector(
    (state) => state.company,
    (company) => {
        return {
            tenantCountry: company.contactData.country,
        };
    }
);

export default slice;
