import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import { jssPreset, StylesProvider } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import GlobalStyles from "@components/global_styles";
import ScrollReset from "@components/scroll_reset";
import { AuthProvider } from "@contexts/auth_context";
import useSettings from "@hooks/use_settings";
import { createAppTheme } from "@themes";
import routes, { RenderRoutes } from "./Routes";
import { StyledEngineProvider } from "@mui/material/styles";
import PermissionProvider from "@contexts/permission/permission_provider";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
    const { settings } = useSettings();

    const theme = createAppTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
                <StyledEngineProvider injectFirst>
                    <LocalizationProvider dateAdapter={MomentUtils}>
                        <SnackbarProvider dense maxSnack={3}>
                            <Router history={history}>
                                <AuthProvider>
                                    <PermissionProvider>
                                        <GlobalStyles />
                                        <ScrollReset />
                                        <RenderRoutes routes={routes} />
                                    </PermissionProvider>
                                </AuthProvider>
                            </Router>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </StyledEngineProvider>
            </StylesProvider>
        </ThemeProvider>
    );
};

export default App;
