import React from "react";
import DashboardLayout from "@layouts/dashboard_layout";
import PropTypes from "prop-types";

const CoachOnboardingLayout = ({ children }) => {
    return <DashboardLayout withNavBar={false}>{children}</DashboardLayout>;
};

CoachOnboardingLayout.propTypes = {
    children: PropTypes.node,
};

export default CoachOnboardingLayout;
