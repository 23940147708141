import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Badge, Box, ButtonBase, CircularProgress, Divider, Grid, IconButton, List, Menu, Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Bell as BellIcon, MoreHorizontal as MoreHorizontalIcon } from "react-feather";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "@store";
import MenuItem from "@mui/material/MenuItem";
import { deleteAll, getNotifications, updateAllAs } from "@slices/notification";
import styles from "./notifications.styles";
import Button from "@components/button";
import NotificationLabel from "@components/notification_label";

const useStyles = makeStyles(styles);

const maxNotificationsShown = 5;

const Notifications = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const ref = useRef(null);
    const refMore = useRef(null);
    const storeDispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const loading = useSelector((state) => _.get(state, "notification.loading", false));
    const notifications = useSelector((state) => _.get(state, "notification.notifications", []));
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const handleOpen = async () => {
        setOpen(true);
        await markAllNotificationsAsSeen();
    };

    const handleClose = () => {
        setOpen(false);
        setOpenMenu(null);
    };

    const handleClick = () => {
        setOpenMenu(!openMenu);
    };

    const handleMoreClose = () => {
        setOpenMenu(null);
    };

    useEffect(() => {
        // TODO will change when routes refactor
        loadNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const loadNotifications = async () => {
        const { error } = await storeDispatch(getNotifications());
        if (error) {
            enqueueSnackbar(t("notifications.snackbar.getAll.error"), {
                variant: "error",
            });
        }
    };

    const seenCounter = notifications.reduce((acc, notification) => {
        return notification.seen === false ? acc + 1 : acc;
    }, 0);

    const clearAllNotifications = async () => {
        const { error } = await storeDispatch(deleteAll());
        if (!error) {
            enqueueSnackbar(t("notifications.snackbar.clearAll.success"), {
                variant: "success",
            });
        } else {
            enqueueSnackbar(t("notifications.snackbar.clearAll.error"), {
                variant: "error",
            });
        }
    };

    const markAllNotificationsAsRead = async () => {
        await storeDispatch(updateAllAs("read"));
    };

    const markAllNotificationsAsSeen = async () => {
        await storeDispatch(updateAllAs("seen"));
    };

    return (
        <React.Fragment>
            <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
                <Badge className={classes.badge} badgeContent={seenCounter} color="error" overlap="circular">
                    <BellIcon className={classes.avatar} />
                </Badge>
            </Box>
            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Box p={2}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5" color="textPrimary" className={classes.boldText}>
                                {t("notifications.title")}
                            </Typography>
                        </Grid>
                        {notifications.length > 0 && (
                            <Grid item>
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls="long-menu"
                                    aria-expanded={openMenu ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={() => handleClick()}
                                    className={classes.moreButton}
                                >
                                    <MoreHorizontalIcon ref={refMore} />
                                    <Menu
                                        id="simple-menu"
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        anchorEl={refMore.current}
                                        keepMounted
                                        open={Boolean(openMenu)}
                                        onClose={handleMoreClose}
                                    >
                                        <MenuItem onClick={async () => await markAllNotificationsAsRead()}>
                                            <Typography variant="body2">{t("notifications.more.readAll")}</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={async () => await clearAllNotifications()}>
                                            <Typography variant="body2">{t("notifications.more.clearAll")}</Typography>
                                        </MenuItem>
                                    </Menu>
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {notifications.length === 0 ? (
                    <>
                        <Divider />
                        <Box p={2}>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    {loading ? (
                                        <CircularProgress color="primary" size={20} className={classes.loading} />
                                    ) : (
                                        <Typography variant="body2" color="textPrimary">
                                            {t("notifications.empty")}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <>
                        <List disablePadding>
                            {notifications.slice(0, maxNotificationsShown).map((notification) => {
                                return <NotificationLabel key={notification.id} divider={true} className={classes.item} {...notification} />;
                            })}
                        </List>
                        <Box p={1} display="flex" justifyContent="center">
                            <Button component={RouterLink} size="small" to="/dashboard/notifications">
                                <Typography variant="body1">{t("notifications.seeAll")}</Typography>
                            </Button>
                        </Box>
                    </>
                )}
            </Popover>
        </React.Fragment>
    );
};

export default Notifications;
