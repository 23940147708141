import { createTheme, colors, responsiveFontSizes } from "@mui/material";
import { softShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
    name: "XFAKTOR",
    direction: "ltr",
    typography: typography,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            tablet: 768,
            md: 960,
            lg: 1280,
            laptop: 1400,
            xl: 1920,
        },
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.MuiInputBase-root.Mui-disabled": {
                        backgroundColor: "#F8F8F8",
                    },
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "&.Mui-selected:focus": {
                        backgroundColor: "#87BB3E",
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    "&.Mui-error": {
                        fontSize: "1rem",
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: "#F8F8F8",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#DD3730",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    color: "white",
                },
            },
        },
    },
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: "hidden",
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiChip: {
            root: {
                backgroundColor: "rgba(0,0,0,0.075)",
            },
        },
        MuiInputBase: {
            input: {
                "&::placeholder": {
                    opacity: 1,
                    color: colors.blueGrey[600],
                },
            },
        },
    },
    palette: {
        type: "light",
        action: {
            active: colors.blueGrey[600],
        },
        background: {
            default: "#F8F8F8",
            main: "#F1F5F9",
            light: "#C4C4C4",
            dark: "#FCFCFC",
            white: "#fff",

            paper: colors.common.white,
        },
        dark: {
            main: "#446318",
        },
        greenGray: {
            main: "#546E7A",
        },
        lightGray: {
            main: "#EEEEEE",
        },
        primary: {
            main: "#87BB3E",
            light: "#FFFFFF",
            dark: "#446318",
        },
        secondary: {
            light: "#FFFFFF",
            main: "#87BB3E",
            dark: "#000000",
        },
        tertiary: {
            main: "#0d9de0",
            light: "rgba(57, 73, 171, 0.05)",
        },
        error: {
            main: "#D62755",
            hover: "#971739",
        },
        success: {
            main: "#4CAF50",
            contrastText: "#FFF",
            dark: "#357A38",
        },
        text: {
            light: "#546E7A",
            dark: "#000",
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
            primaryContrastText: "#FFF",
            secondaryContrastText: "#263238",
            neutral: "#5E6368",
        },
        warning: {
            light: "#FFF2E2",
            main: "#FF9800",
        },
        general: {
            neutral: "#E0E0E0",
        },
    },
    shadows: softShadows,
};

export const createAppTheme = (config = {}) => {
    let theme = createTheme(baseOptions);

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme;
};
