import React, { Fragment, Suspense, useEffect } from "react";
import { matchPath, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardLayout from "@layouts/dashboard_layout";
import CoachOnboardingLayout from "@layouts/coach_onboarding_layout";
import HomeLayout from "./layouts/home_layout";
import AuthLayout from "./layouts/auth_layout";
import LoadingScreen from "@components/loading_screen";
import AuthGuard from "@components/auth_guard";
import GuestGuard from "@components/guest_guard";
import _ from "lodash";
import { Helmet } from "react-helmet";
import Restricted from "@components/restricted";

export const pages = [
    // require("@pages/welcome/index.routes"),
    require("@pages/auth/login/index.routes"),
    require("@pages/auth/logout/index.routes"),
    require("@pages/auth/callback/index.routes"),
    require("@pages/auth/reset_password/index.routes"),
    require("@pages/auth/register/index.routes"),
    require("@pages/auth/confirm_registration/index.routes"),
    require("@pages/accept_invitation/index.routes"),
    require("@pages/reject_invitation/index.routes"),
    require("@pages/dashboard/advert/advert_list/index.routes"),
    require("@pages/dashboard/advert/advert_edit/index.routes"),
    require("@pages/dashboard/contact/index.routes"),
    require("@pages/dashboard/catalog/booking/booking_list/index.routes"),
    require("@pages/dashboard/catalog/booking/booking_view/index.routes"),
    require("@pages/dashboard/catalog/product/product_list/index.routes"),
    require("@pages/dashboard/catalog/product/product_edit/index.routes"),
    require("@pages/dashboard/catalog/enquiry/enquiry_list/index.routes"),
    require("@pages/dashboard/catalog/enquiry/enquiry_view/index.routes"),
    require("@pages/dashboard/catalog/product_groups/product_groups_list/index.routes"),
    require("@pages/dashboard/catalog/product_groups/product_groups_edit/index.routes"),
    require("@pages/dashboard/account/company_edit/index.routes"),
    require("@pages/dashboard/account/user_edit/index.routes"),
    require("@pages/dashboard/notification/notification_list/index.routes"),
    require("@pages/dashboard/notification/notification_edit/index.routes"),
    require("@pages/dashboard/overview/index.routes"),
    require("@pages/coach/start/index.routes"),
    require("@pages/coach/onboarding/index.routes"),
    require("@pages/coach/onboarding_complete/index.routes"),
    require("@pages/404/index.routes"),
];

const layouts = {
    home: HomeLayout,
    auth: AuthLayout,
    dashboard: DashboardLayout,
    coachOnboarding: CoachOnboardingLayout,
};

const guards = {
    auth: AuthGuard,
    guest: GuestGuard,
};

export const routes = pages.reduce((acc, route) => {
    acc = route.default ? _.concat(acc, route.default) : acc;
    return acc;
}, []);

const RouteComponent = ({ title, ...props }) => {
    const MainComp = props.component;

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <MainComp {...props} />
        </>
    );
};

export const RenderRoutes = ({ routes = [] }) => {
    const { t } = useTranslation();
    const url = useLocation();
    const matchRoute = routes.find((route) => {
        return matchPath(route.path, url.pathname);
    });

    const routeGuard = _.get(matchRoute, "guard");
    const Guard = _.get(guards, routeGuard, Fragment);

    const matchLayout = _.get(matchRoute, "layout");
    const Layout = _.get(layouts, matchLayout, Fragment);

    return (
        <Guard>
            <Layout>
                <Suspense fallback={<LoadingScreen />}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        {routes.map((route, i) => {
                            const { component, exact, path, title, scope } = route;

                            const translatedTitle = t(title);
                            const baseTitle = `${translatedTitle ? translatedTitle + " - " : ""}Semigator partners`;

                            return (
                                <Route
                                    key={i}
                                    path={path}
                                    exact={exact}
                                    element={
                                        <Restricted to={scope} redirectPath={"/dashboard"}>
                                            <RouteComponent title={baseTitle} component={component} />
                                        </Restricted>
                                    }
                                />
                            );
                        })}
                        <Route path="/404" element={<Navigate to="/404" />} />
                    </Routes>
                </Suspense>
            </Layout>
        </Guard>
    );
};

export default routes;
