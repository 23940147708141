const styles = (theme) => ({
    avatar: {
        height: 20,
        width: 20,
        marginRight: theme.spacing(1),
    },

    badge: {
        display: "start",
        // marginRight: theme.spacing(3),
    },
    popover: {
        width: 440,
    },
    icon: {
        color: theme.palette.secondary.contrastText,
    },
    badgeSuccess: {
        color: "green",
    },
    deleteNotification: {
        height: 20,
        width: 20,
    },
    moreButton: {
        padding: 0,
    },
    boldText: {
        fontWeight: "bold",
    },
    loading: {
        marginLeft: theme.spacing(2),
    },
    item: {
        padding: "8px 16px",
    },
    listItem: {
        listStyle: "none",
        border: "none",
        width: "100%",
        "& ::marker": {
            display: "none",
        },
    },
    listItemRead: {},
    listItemNotRead: {
        // backgroundColor: "#F4F6F8",
    },
});

export default styles;
