import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: "/dashboard/catalog/products",
        layout: "dashboard",
        title: "routes.products",
        component: lazy(() => import("./index")),
    },
];

export default routes;
