import { lazyRetry as lazy } from "@utils/lazyRetry";

export const DASHBOARD_PATH = "/dashboard";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: DASHBOARD_PATH,
        layout: "dashboard",
        title: "Dashboard",
        component: lazy(() => import("./index")),
    },
    {
        guard: "auth",
        path: `${DASHBOARD_PATH}/*`,
        layout: "dashboard",
        title: "Dashboard",
        component: lazy(() => import("./index")),
    },
];

export default routes;
