import { lazyRetry as lazy } from "@utils/lazyRetry";
import { SCOPES } from "@contexts/permission";

const routes = [
    {
        exact: true,
        scope: SCOPES.DISCOUNTS.VIEW,
        guard: "auth",
        path: "/dashboard/catalog/product-groups",
        layout: "dashboard",
        title: "routes.discounts",
        component: lazy(() => import("./index")),
    },
];

export default routes;
