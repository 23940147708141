import { Avatar, CircularProgress, Divider, Grid, IconButton, ListItemAvatar, ListItemText, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Badge from "@mui/material/Badge";
import clsx from "clsx";
import { X as CloseIcon } from "react-feather";
import React, { useState } from "react";
import styles from "./index.styles";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { getFormattedDate } from "@src/libs/utilsLib";
import { deleteOne, updateOneAsRead } from "@slices/notification";
import { useDispatch, useSelector } from "@store";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useIsMounted from "@hooks/use_is_mounted_ref";
import { severityMapping, iconsMapping, flowsMapping } from "@components/notification_label/mappings";

const useStyles = makeStyles(styles);

const NotificationLabel = (props) => {
    const { id, type, severity, message, read, createdAt, itemProps = {}, divider = false, className, disableHighlight = false } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const storeDispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useNavigate();
    const [loadAction, setLoadAction] = useState(false);
    const notificationText = t(`notifications.text.${type}.${severity}`, t(message));
    const severityMap = _.get(severityMapping, severity, severity.default);
    const Icon = _.get(iconsMapping, type, iconsMapping.default);
    const flows = flowsMapping(props, history);
    const flowMap = _.get(
        flows,
        `${type}.${severity}`,
        _.get(
            flows,
            `${type}.default`,
            _.get(flows, "default.default", () => {})
        )
    );
    const loading = useSelector((state) => _.get(state, "notification.loading", false));
    const isMountedRef = useIsMounted();

    const markOneNotificationAsRead = (id) => async () => {
        await flowMap();
        if (read === false) {
            isMountedRef() && setLoadAction(true);
            await storeDispatch(updateOneAsRead(id));
            isMountedRef() && setLoadAction(false);
        }
    };

    const clearNotification = (id) => async () => {
        isMountedRef() && setLoadAction(true);

        const { error } = await storeDispatch(deleteOne(id));

        if (!error) {
            enqueueSnackbar(t("notifications.snackbar.clearOne.success"), {
                variant: "success",
            });
        } else {
            enqueueSnackbar(t("notifications.snackbar.clearOne.error"), {
                variant: "error",
            });
        }

        isMountedRef() && setLoadAction(false);
    };

    return (
        <Grid container className={classes.listItemContainer}>
            <Grid item xs={12} className={clsx(className, !disableHighlight && !read && classes.listItemNotRead)}>
                <Grid
                    container
                    key={id}
                    className={clsx(classes.listItem, read ? classes["listItemRead"] : classes["listItemNotRead"])}
                    alignItems="center"
                    {...itemProps}
                >
                    <Grid item onClick={markOneNotificationAsRead(id)}>
                        <ListItemAvatar>
                            <Avatar variant="rounded" className={classes.icon}>
                                <Badge
                                    sx={{ "& .MuiBadge-badge": {} }}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    badgeContent={severityMap.symbol}
                                    color={severityMap.color}
                                    className={classes.badge}
                                >
                                    <SvgIcon fontSize="small">
                                        <Icon />
                                    </SvgIcon>
                                </Badge>
                            </Avatar>
                        </ListItemAvatar>
                    </Grid>
                    <Grid item xs onClick={markOneNotificationAsRead(id)}>
                        <ListItemText
                            primary={notificationText}
                            primaryTypographyProps={{
                                variant: "h6",
                                color: "textPrimary",
                            }}
                            secondary={getFormattedDate(createdAt, true)}
                            secondaryTypographyProps={{
                                variant: "caption",
                                color: "textSecondary",
                            }}
                        />
                    </Grid>
                    <Grid item>
                        {loading || loadAction ? (
                            <CircularProgress color="primary" size={20} className={classes.loading} />
                        ) : (
                            <IconButton edge="end" aria-label="delete" onClick={clearNotification(id)}>
                                <SvgIcon className={classes.deleteNotification}>
                                    <CloseIcon />
                                </SvgIcon>
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {divider && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}
        </Grid>
    );
};

export default NotificationLabel;
