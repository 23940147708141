import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: "/dashboard/catalog/enquiries",
        layout: "dashboard",
        title: "routes.enquiries",
        component: lazy(() => import("./index-semcore")),
    },
];

export default routes;
