import { createSlice } from "@reduxjs/toolkit";
import { callApi, Endpoints } from "@utils/api";
import _ from "lodash";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    id: "",
    language: "de",
    role: "",
};

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUser(state, action) {
            const data = _.get(action, "payload");
            if (!data) {
                return state;
            }
            const { firstName, lastName, email, id, language, role } = data || {};
            state = _.merge(state, {
                firstName,
                lastName,
                email,
                id,
                language,
                role,
            });
        },
    },
});

export const reducer = slice.reducer;

export const setUser = (data) => async (dispatch) => {
    dispatch(slice.actions.getUser(data));
    return { data };
};

export const getUser = (userId) => async (dispatch) => {
    const { data, error } = await callApi(Endpoints.GetUser(userId));

    if (data && data.user) {
        dispatch(slice.actions.getUser(data.user));
        return { data };
    }

    return { error: error };
};

export const updateUser = (userId, payload) => async (dispatch) => {
    const { data, error } = await callApi(Endpoints.UpdateUser(userId), _.omitBy(payload, _.isUndefined), "put");

    if (data && data.user) {
        dispatch(slice.actions.getUser(data.user));
        return { data };
    }

    return { error: error };
};

export default slice;
