import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import NavBar from "./nav_bar";
import TopBar from "./top_bar";
import { useTranslation } from "react-i18next";
import { useSelector } from "@store";
import clsx from "clsx";
import styles from "./index.styles";
import ImportAlert from "@layouts/dashboard_layout/top_bar/import_alert";

const useStyles = makeStyles(styles);

const DashboardLayout = ({ children, withNavBar = true }) => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const { i18n } = useTranslation();

    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (user.language) {
            i18n.changeLanguage(user.language);
        }
    }, [i18n, user.language]);

    return (
        <div className={classes.root}>
            <TopBar showBurgerMenu={withNavBar} onMobileNavOpen={() => setMobileNavOpen((value) => !value)} />
            {withNavBar && <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />}
            <div className={clsx(classes.wrapper, withNavBar ? classes.withMenu : {})}>
                <div className={classes.contentContainer}>
                    <ImportAlert />
                    <div className={clsx(classes.content, classes.contentWide)}>{children}</div>
                </div>
            </div>
        </div>
    );
};

DashboardLayout.propTypes = {
    children: PropTypes.node,
};

export default DashboardLayout;
