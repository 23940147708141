import { DefaultPermissions, PERMISSIONS, updatePermissions } from "@contexts/permission";
import { selectTenantPermissions } from "@slices/company";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useAuth from "@hooks/use_auth";

/**
 * Hook used to get all permissions,
 * other permissions could be added based on different conditions
 */
const usePermissions = () => {
    const { allowAdverts, allowDiscounts } = useSelector(selectTenantPermissions);
    const { isImpersonated } = useAuth();
    const [permissions, setPermissions] = useState(DefaultPermissions);

    useEffect(() => {
        // get full access to adverts feature
        setPermissions((value) => updatePermissions(value, PERMISSIONS.ADVERTS, allowAdverts || false));
        // get full access to discounts feature
        setPermissions((value) => updatePermissions(value, PERMISSIONS.DISCOUNTS, allowDiscounts || false));
        setPermissions((value) => updatePermissions(value, PERMISSIONS.PROFILE, !isImpersonated));
        // e.g. remove access for creating new discount
        // permissions = updatePermissions(permissions, [SCOPES.DISCOUNTS.CREATE], false)
        // e.g. remove access for editing a discount
        //  permissions = updatePermissions(permissions, [SCOPES.DISCOUNTS.EDIT], false)
    }, [allowAdverts, allowDiscounts, isImpersonated]);

    return permissions;
};

export default usePermissions;
