import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "@slices/user";
import { reducer as companyReducer } from "@slices/company";
import { reducer as notificationReducer } from "@slices/notification";
import { reducer as importReducer } from "@slices/import";

const rootReducer = combineReducers({
    user: userReducer,
    company: companyReducer,
    notification: notificationReducer,
    import: importReducer,
});

export default rootReducer;
