import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, ButtonBase, Menu, MenuItem, Avatar, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import styles from "./account.styles";
import UserLogo from "@assets/img/user.svg";
import { useSelector } from "@store";
import { useTranslation } from "react-i18next";
import Restricted from "@components/restricted";
import { SCOPES } from "@contexts/permission";

const useStyles = makeStyles(styles);

const Account = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const user = useSelector((state) => state.user);
    const xsDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const [isOpen, setOpen] = useState(false);

    const { t } = useTranslation();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref} data-cy="avatar">
                <Avatar
                    alt="User"
                    className={classes.avatar}
                    src={UserLogo} //user.avatar
                />
                {user && !xsDown && (
                    <Typography className={classes.name} variant="h5">
                        {_.get(user, "firstName", "") + " " + _.get(user, "lastName", "")}
                    </Typography>
                )}
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                keepMounted
                PaperProps={{ className: classes.popover }}
                anchorEl={ref.current}
                open={isOpen}
            >
                <Restricted to={SCOPES.PROFILE.VIEW}>
                    <MenuItem component={RouterLink} to="/dashboard/account/profile" onClick={handleClose}>
                        {t("account.profile")}
                    </MenuItem>
                </Restricted>
                <MenuItem component={RouterLink} data-cy="logout" to="/logout" onClick={handleClose}>
                    {t("account.logout")}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

export default Account;
