import { Button as MaterialButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import styles from "./index.styles";
import React from "react";

const useStyles = makeStyles(styles);

const Button = ({ className, color = "primary", ...props }) => {
    const classes = useStyles();

    const colors = {
        primary: { className: clsx(classes.primary, className), color: color },
        "primary-outlined": {
            className: clsx(classes.primaryOutlined, className),
            color: "primary",
        },
        inherit: { className: clsx(classes.inherit, className), color: color },
        default: { className: clsx(classes.default, className), color: color },
        secondary: {
            className: clsx(classes.secondary, className),
            color: "primary",
        },
        tertiary: {
            className: clsx(classes.tertiary, className),
            color: "primary",
        },
        success: {
            className: clsx(classes.success, className),
            color: "primary",
        },
        error: { className: clsx(classes.error, className), color: "primary" },
        "error-outlined": {
            className: clsx(classes.errorOutlined, className),
            color: "error",
        },
    };

    return <MaterialButton className={colors[color].className} color={colors[color].color} {...props} />;
};

export default Button;
