import { create, isCancel } from "apisauce";
import get from "lodash/get";
import { keysToSnake, keysToCamel } from "@utils/utils";
import TAwsConfig from "../awsConfig";
import { Auth } from "aws-amplify";
import config from "../config";
import qs from "qs";

// TODO: use Amplify.API instead of apisauce here

const Api = create({
    baseURL: TAwsConfig.apiGateway.URL,
    timeout: 50000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

const UNEXPECTED_ERROR_MESSAGE = "An unexpected error occurred.";

export const HttpStatus = Object.freeze({
    OK: 200,
    BAD_REQUEST: 400,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
});

export const Endpoints = {
    ResendConfirmationEmail: `/auth/resend-confirmation`,
    ConfirmRegistration: (token) => `/auth/confirm-registration/${token}`,
    ConfirmInvitation: (token) => `/auth/confirm-invitation/${token}`,
    Login: `/auth/login`,
    Logout: `/auth/logout`,
    ValidateToken: `/auth/forgot_password`,
    RequestResetPassword: "/auth/forgot_password",
    ConfirmResetPasswordRequest: "/auth/forgot_password/",
    ResetPassword: "/auth/forgot_password",
    CheckEmail: "/auth/email_availability",
    CheckIdp: "/auth/idp",
    GetUser: (id) => `/users/${id}`,
    GetUsers: `/users`,
    GetClients: `/clients`,
    UpdateUser: (id) => `/users/${id}`,
    GetTenant: (id) => `/tenants/${id}`,
    GetTenantCognitoForEmail: (email) => `/auth?email=${encodeURIComponent(email)}`,
    UpdateTenant: (id) => `/tenants/${id}`,
    GetBooking: (id) => `/bookings/${id}`,
    UpdateBooking: (id) => `/bookings/${id}`,
    GetBookings: `/bookings`,
    GetEnquiry: (id) => `/enquiries/${id}`,
    UpdateEnquiry: (id) => `/enquiries/${id}`,
    GetEnquiries: `/enquiries`,
    GetNotifications: `/notifications`,
    PatchNotification: (id) => `/notifications/${id}`,
    DeleteNotification: (id) => `/notifications/${id}`,
    GetProductRuleGroups: `/rule_groups`,
    ProductGroups: `/product_groups`,
    ProductGroup: (id) => `/product_groups/${id}`,
    ProductGroupAssign: (id) => `/product_groups/${id}/assign`,
    Products: `/products`,
    Product: (productId) => `/products/${productId}`,
    ProductItems: (productId) => `/products/${productId}/product_items`,
    ProductItem: (productId, productItemId) => `/products/${productId}/product_items/${productItemId}`,
    DuplicateProductItem: (productId, productItemId) => `/products/${productId}/product_items/${productItemId}/duplicate`,
    GetProduct: (id) => `/products/${id}`,
    ExportCSVProducts: `/products/exportcsv`,
    DuplicateProduct: (id) => `/products/${id}/duplicate`,
    AcceptInvitation: (id) => `/invitations/accept_invitation/${id}`,
    RejectInvitation: (id) => `/invitations/reject_invitation/${id}`,
    //TODO: need this route from BFF
    S3SignedUrl: config.webapp.COMPANY_PROFILE_LOGO_SIGNED_URL,
    ProductsBulk: "/bulk/products",
    ProductItemsBulk: "/bulk/product_items",
    ProductsBulkStatus: "/bulk/products/status",
    ProductItemsBulkStatus: "/bulk/product_items/status",
    Notification: (id) => `/notifications/${id}`,
    Adverts: "/adverts",
    Advert: (advertId) => `/adverts/${advertId}`,
    GetDashboard: "/dashboard",
    GetCities: (country, term) => `/locations?country=${country}&term=${term}`,
    GetCoachProfiles: "/coach_profiles",
    GetCoachSettings: "/coach_profiles/settings",
};

export const UnprotectedEndpoints = {
    Register: `/auth/register`,
    CheckInvitationCode: (id) => `/invitations/check_invitation/${id}`,
};

const defaultConfig = {
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
};

export const callApi = async (endpoint, params, method = "get", config = {}, addAuthorizationToRequest = true, disabledCamelResponse = false) => {
    let tokenConfig = null;
    try {
        if (addAuthorizationToRequest) {
            const getAwsCredentials = await Auth.currentSession().catch(() => {
                document.location.href = "/login";
            });
            const idToken = getAwsCredentials.getIdToken().jwtToken;
            const impersonatedTenantId = window.sessionStorage.getItem("impersonatedTenantId");

            tokenConfig = {
                headers: {
                    ...(idToken ? { Authorization: "Bearer " + idToken } : {}),
                    ...(impersonatedTenantId ? { impersonatedtenantid: impersonatedTenantId } : {}),
                },
            };
        }
        const options = tokenConfig ? { ...defaultConfig, ...tokenConfig, ...config } : { ...defaultConfig, ...config };

        const { ok, data, headers, status } = await Api[`${method}`](endpoint, keysToSnake(params), options);

        if (ok) {
            return {
                data: disabledCamelResponse ? data : keysToCamel(data),
                headers,
            };
        }

        const errorMsg = get(data, "error.msg", get(data, "message"));
        const errorCode = get(data, "error.code", get(data, "statusCode"));

        if (errorMsg && errorCode) {
            return {
                error: { message: errorMsg, code: errorCode, status: status },
            };
        }

        throw new Error(UNEXPECTED_ERROR_MESSAGE);
    } catch (err) {
        if (isCancel(err)) {
            console.log("Request canceled", err.message);
            return;
        }

        const errorMessage = get(err, "message", UNEXPECTED_ERROR_MESSAGE);
        const errorStatus = get(err, "status", 500);
        return { error: { message: errorMessage, status: errorStatus } };
    }
};
