import moment from "moment-timezone";

export const getFormattedDate = (date, timeIncluded) => {
    return date && moment(date).isValid() ? (timeIncluded ? moment(date).format("DD/MM/YYYY HH:mm") : moment(date).format("DD/MM/YYYY")) : date;
};

export const getFormattedTime = (timeMoment) => {
    return timeMoment && moment(timeMoment).isValid() ? moment(timeMoment).format("HH:mm") : "";
};

export const getFormattedPrice = (data) => {
    return isNaN(data)
        ? "0"
        : `€ ${new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
          })
              .format(data)
              .replace(/.$/, "")}`;
};
export const getDisplayId = (data) => {
    return data.toString().split("-")[0];
};
