import { lazyRetry as lazy } from "@utils/lazyRetry";
import { SCOPES } from "@contexts/permission";

const routes = [
    {
        exact: true,
        scope: SCOPES.PROFILE.VIEW,
        guard: "auth",
        path: "/dashboard/account/profile",
        layout: "dashboard",
        title: "routes.editUser",
        component: lazy(() => import("./index")),
    },
];

export default routes;
