import { lazyRetry as lazy } from "@utils/lazyRetry";

export const COACH_START_PATH = "/coach-start";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: COACH_START_PATH,
        layout: "coachOnboarding",
        component: lazy(() => import("./index")),
    },
];

export default routes;
