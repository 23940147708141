import { createContext } from "react";

export const SCOPES = {
    ADVERTS: {
        CREATE: "adverts:create",
        VIEW: "adverts:view",
        EDIT: "adverts:edit",
        DELETE: "adverts:delete",
    },
    DISCOUNTS: {
        CREATE: "discounts:create",
        VIEW: "discounts:view",
        EDIT: "discounts:edit",
        DELETE: "discounts:delete",
    },
    PROFILE: {
        VIEW: "profile:view",
    },
};
export const PERMISSIONS = {
    ADVERTS: [SCOPES.ADVERTS.CREATE, SCOPES.ADVERTS.VIEW, SCOPES.ADVERTS.EDIT, SCOPES.ADVERTS.DELETE],
    DISCOUNTS: [SCOPES.DISCOUNTS.CREATE, SCOPES.DISCOUNTS.VIEW, SCOPES.DISCOUNTS.EDIT, SCOPES.DISCOUNTS.DELETE],
    PROFILE: [SCOPES.PROFILE.VIEW],
};

const getDefaultPermissions = () => {
    const permissions = {};
    for (const feature in PERMISSIONS) {
        for (const scope in PERMISSIONS[feature]) {
            permissions[PERMISSIONS[feature][scope]] = true;
        }
    }

    return permissions;
};

export const updatePermissions = (permissions, scopes, allow = true) => {
    const result = { ...permissions };
    scopes.forEach((scope) => {
        result[scope] = allow;
    });

    return result;
};

// All scopes are allowed by default, only specific scopes should be denied if needed
export const DefaultPermissions = {
    ...getDefaultPermissions(),
    // [SCOPES.ADVERTS.CREATE]: false, // e.g. for removing default permission
};

const defaultBehavior = {
    isAllowed: () => false,
};
const PermissionContext = createContext(defaultBehavior);

export default PermissionContext;
