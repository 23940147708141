import { THEMES } from "@src/constants";

const styles = (theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: "none",
                  backgroundColor: theme.palette.primary.main,
              }
            : {
                  backgroundColor: theme.palette.background.white,
              }),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                  backgroundColor: theme.palette.background.default,
              }
            : {
                  backgroundColor: theme.palette.background.white,
              }),
    },
    logo: {
        maxHeight: 34,
    },
    logoLink: {
        lineHeight: 0,
        display: "flex",
    },
    burgerIcon: {
        paddingRight: 20,
    },
    toolbar: {
        minHeight: 64,
    },
});

export default styles;
